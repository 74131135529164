//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { IamPermissions } from '@/modules/iam/iam-permissions';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'app-iam-view-toolbar',

  data() {
    return {
      
    }
  },

   // mapGetters to return loading , currentUser , record

  computed: { 
    ...mapGetters({
      currentUser: 'auth/currentUser',
      record: 'iam/view/record',
      loading: 'iam/view/loading',
    }),

    // this computed  for enableRtl

    isRTL() {
      return localStorage.getItem('language') == 'ar'
    },

    //  check if the user has permission to edit 

    hasPermissionToEdit() {
      return new IamPermissions(this.currentUser).edit;
    },

  },

  methods: {

     //  call doToggleStatus of currentuser
    ...mapActions({
      doToggleStatus: 'iam/view/doToggleStatus',
    }),
  },
};
